export const skills = [
  "HTML",
  "CSS",
  "JavaScript",
  "React",
  "Redux",
  "Node.js",
  "SQL",
  "NoSQL",
  "GraphQL",
  "SASS/SCSS",
  "SPAs",
  "Bootstrap",
  "APIs",
  "REST",
  "Git",
  "Testing",
  "TDD",
  "Problem Solving",
  "Communication",
  "Attention to detail",
  "Adaptability",
  "Adobe Premiere Pro",
  "Adobe Photoshop",
  "Adobe Audition",
];
