export const projects = [
  {
    title: "Portfolio",
    image: [
      "portfolio-mobile.png",
      "portfolio-tablet.png",
      "portfolio-laptop.png",
      "portfolio-desktop.png",
    ],
    description:
      "This portfolio was built using React and SCSS, with a Node.js backend and a MongoDB database. It's fully responsive and features plenty of interactivity with CSS and GreenSock animations.",
    demoLink: "https://fredpostles.com/",
    gitHubLinkFront: "https://github.com/fredpostles/portfolio",
    gitHubLinkBack: "https://github.com/fredpostles/portfolio-backend",
    techs: [
      "HTML",
      "SASS",
      "JavaScript",
      "React",
      "NodeJS",
      "MongoDB",
      "GreenSock",
    ],
  },
  {
    title: "Make Food, Not Waste",
    image: [
      "mfnw-mobile.png",
      "mfnw-tablet.png",
      "mfnw-laptop.png",
      "mfnw-desktop.png",
    ],
    description: `A single page application that empowers users to reduce food waste by efficiently managing their pantry items and discovering recipes based on available ingredients. Users can add ingredients to their pantry and search for recipes that will utilize them. They can also save recipes they like for later. The frontend was built with React and Redux. The backend was written in Node.js, using the Express framework, and uses SQL to query the MySQL (MariaDB) database. Test login: email: demo[at]makefoodnotwaste.co.uk, password: demoversion`,
    demoLink: "https://makefoodnotwaste.co.uk/",
    gitHubLinkFront: "https://github.com/fredpostles/food-waste-app",
    gitHubLinkBack: "https://github.com/fredpostles/mfnw-backend",
    techs: [
      "HTML",
      "CSS",
      "JavaScript",
      "React",
      "Redux",
      "REST APIs",
      "NodeJS",
      "SQL",
    ],
  },
  {
    title: "Simpsons Quotes",
    image: [
      "simpsons-mobile.png",
      "simpsons-tablet.png",
      "simpsons-laptop.png",
      "simpsons-desktop.png",
    ],
    description:
      "My first React project was built using Class components and the Simpsons Quotes API to retrieve quotes that the user can then search, sort, favourite and delete.",
    demoLink: "https://simpsons-quotes-react.netlify.app/",
    gitHubLinkFront: "https://github.com/fredpostles/simpsons-api-task",
    gitHubLinkBack: null,
    techs: ["HTML", "CSS", "JavaScript", "React", "REST APIs"],
  },
  {
    title: "World Weather",
    image: [
      "world-weather-mobile.png",
      "world-weather-tablet.png",
      "world-weather-laptop.png",
      "world-weather-desktop.png",
    ],
    description:
      "Written in vanilla JavaScript, this weather website uses the Visual Crossing API to retrieve weather data and forecasts for the next 2 weeks, wherever the user is in the world. The Open Weather Map and Mapbox APIs are utilised for location lookup and map search features.",
    demoLink: "https://fp-world-weather.netlify.app/",
    gitHubLinkFront: "https://github.com/fredpostles/weather-api",
    gitHubLinkBack: null,
    techs: ["HTML", "CSS", "JavaScript", "REST APIs", "Mapbox"],
  },
  {
    title: "Nowness Homage",
    image: [
      "nowness-mobile.png",
      "nowness-tablet.png",
      "nowness-laptop.png",
      "nowness-desktop.png",
    ],
    description:
      "Fully-responsive homage version of nowness.com. Recreated using only HTML and CSS, demonstrating my ability to faithfully reproduce any design in pixel-perfect style.",
    demoLink: "https://nowness-homage-fp.netlify.app/",
    gitHubLinkFront: "https://github.com/fredpostles/nowness-homage-piece",
    gitHubLinkBack: null,
    techs: ["HTML", "CSS"],
  },
  {
    title: "Responsive Four Card Feature",
    image: [
      "four-card-feature-mobile.png",
      "four-card-feature-tablet.png",
      "four-card-feature-laptop.png",
      "four-card-feature-desktop.png",
    ],
    description:
      "Reponsive four card design coded in SASS, based on a mock up design.",
    demoLink: "https://responsive-four-card-feature-scss.netlify.app/",
    gitHubLinkFront: null,
    gitHubLinkBack: null,
    techs: ["HTML", "SASS"],
  },
];
